import React, { useEffect, useState } from "react";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { Header } from "../components/layout/header/Header";
import { Footer } from "../components/layout/footer/Footer";
import { Link } from "react-router-dom";

export const Blog = () => {
  const [newsList, setNewsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3; // Number of items to display per page

  useEffect(() => {
    fetch("https://webbitech.co.in/PHP-BETA/vista/api/get-blog")
      .then((response) => response.json())
      .then((json) => setNewsList(json));
  }, []);

  // Calculate the index of the first and last item on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = newsList.slice(indexOfFirstItem, indexOfLastItem);

  // Total pages
  const totalPages = Math.ceil(newsList.length / itemsPerPage);

  // Pagination controls
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0); // Scroll to top on page change
  };

  return (
    <div>
      <Header />

      {/* Sub banner */}
      <div className="sub_banner">
        <div className="container">
          <div className="banner_content_desc">
            <h5 className="banner_subtitle">Blog</h5>
            <h1 className="banner_title">
              Stay Updated with VISTA's Latest Blog
            </h1>
            <div className="breadcrumb_detail">
              <div className="breadcrumb_box_grey">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Blog
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="inner_blog_list">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <h2 className="inner_blog_title">Blogs</h2>
              <p className="inner_blog_text">
                As a global leader in engineering and technology solutions, we
                deliver cutting-edge advancements that drive success across
                industries, bridging the gap between technology and practical
                application.
              </p>
            </div>
          </div>
          <div className="inner_blog_card_list">
            <div className="row gy-4">
              {currentItems.map((item) => {
                return (
                  <div className="col-lg-4 col-md-6" key={item.id}>
                    <div className="blog_box">
                      <div className="card_img">
                        <img src={item.image_url} alt="" />
                        <div className="author_detail">
                          <ul>
                            <li>{item.news_date}</li>
                          </ul>
                        </div>
                      </div>
                      <div className="card_body">
                        <h5 className="card_title">{item.title}</h5>
                        <p className="card_text">{item.short_description}</p>
                        <p className="card_link mt-3">
                          <Link
                            to={`/Blog/${item.id}`}
                            onClick={() => window.scrollTo(0, 0)}
                            className="card_link">
                            Read More <i className="bi bi-chevron-right"></i>
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Pagination Controls */}
          <div className="pagination mt-4">
            <nav aria-label="Page navigation">
              <ul className="pagination">
                <li
                  className={`page-item ${
                    currentPage === 1 ? "disabled" : ""
                  }`}>
                  <button
                    className="page-link prev"
                    onClick={() => handlePageChange(currentPage - 1)}
                    aria-label="Previous">
                    <i class="bi bi-chevron-left"></i>
                  </button>
                </li>
                {[...Array(totalPages)].map((_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      currentPage === index + 1 ? "active" : ""
                    }`}>
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}>
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}>
                  <button
                    className="page-link next"
                    onClick={() => handlePageChange(currentPage + 1)}
                    aria-label="Next">
                    <i class="bi bi-chevron-right"></i>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
