import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import vistaWhiteLogo from "../../../assets/images/vista-white-logo.png";
import "./footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

export const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [count, setCount] = useState(null);
  const [visitorCount, setVisitorCount] = useState({ status: "", count: 1 });

  const [formData, setFormData] = useState({
    email: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://webbitech.co.in/PHP-BETA/vista/api/newsletter-subscribe",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      toast.success("Form submitted successfully!");

      // Reset form
      setFormData({
        email: "",
      });
    } catch (error) {
      toast.error("There was a problem with your submission.");
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    axios
      .post(
        "https://webbitech.co.in/PHP-BETA/vista/api/sent-count",
        { count: 1 },
        {
          headers: {
            "X-CSRF-TOKEN": csrfToken,
          },
        }
      )
      .then((response) => {
        setCount(response.data.count);
      })
      .catch((error) => {
        console.error("Error updating visitor count:", error);
      });
  }, []);

  useEffect(() => {
    fetch("https://webbitech.co.in/PHP-BETA/vista/api/get-count")
      .then((response) => response.json())
      .then((json) => setVisitorCount(json));
  }, []);

  const handleMegaMenuClick = (path, sectionId) => {
    navigate(path, { state: { sectionId } });
  };

  return (
    <>
      <ToastContainer
        autoClose={2000} // Close after 5 seconds
        position="top-right" // Position at the top-right of the screen
        hideProgressBar={false} // Show the progress bar
        closeOnClick={true} // Close the toast when clicked
        pauseOnHover={false} // Pause the timer on hover
        draggable={true} // Allow the toast to be dragged
      />
      <footer className="footer">
        <div className="footer_top">
          <div className="container">
            <div className="row gy-4 align-items-center">
              <div className="col-lg-4 col-md-6">
                <Link exact to="/" onClick={() => window.scrollTo(0, 0)}>
                  <img src={vistaWhiteLogo} alt="" className="footer_logo" />
                </Link>
                <div className="social_icon">
                  <ul>
                    <li>
                      <a href="#">
                        <FontAwesomeIcon icon="fa-brands fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FontAwesomeIcon icon="fa-brands fa-x-twitter" />
                      </a>
                    </li>

                    <li>
                      <a href="https://www.linkedin.com/company/vista-engineering-global/">
                        <FontAwesomeIcon icon="fa-brands fa-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FontAwesomeIcon icon="fa-brands fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FontAwesomeIcon icon="fa-brands fa-youtube" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="footer_center">
                  <h5 className="footer_title">Get in touch with us</h5>
                  <form onSubmit={handleSubmit} class="input-group mt-4">
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      placeholder="Email*"
                      value={formData?.email}
                      onChange={handleChange}
                      required
                    />
                    <button
                      type="submit"
                      class="input-group-text"
                      id="basic-addon2">
                      <i class="bi bi-send-fill"></i>
                    </button>
                  </form>
                </div>
              </div>
              <div className="col-lg-4">
                <h5 className="footer_title">Our Location</h5>
                <div class="hstack gap-2">
                  <div class="footer_text">USA</div>
                  <div class="vr"></div>
                  <div class="footer_text">Germany</div>
                  <div class="vr"></div>
                  <div class="footer_text">India </div>
                  <div class="vr"></div>
                  <div class="footer_text"> AUS</div>
                  <div class="vr"></div>
                  <div class="footer_text">UK </div>
                  <div class="vr"></div>
                  <div class="footer_text">Norway </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_middle">
          <div className="container">
            <div className="row row-cols-1 row-cols-lg-3 row-cols-xl-4 row-cols-md-2 gy-4">
              <div className="col">
                <h5 className="footer_title">Useful Links</h5>
                <ul className="footer_list">
                  <li>
                    <Link
                      exact
                      to="/About"
                      className="footer_link"
                      onClick={() => window.scrollTo(0, 0)}>
                      About
                    </Link>
                  </li>
                  <li>
                    <Link
                      exact
                      to="/Careers"
                      className="footer_link"
                      onClick={() => window.scrollTo(0, 0)}>
                      Careers
                    </Link>
                  </li>
                  <li>
                    <Link
                      exact
                      to="/News"
                      className="footer_link"
                      onClick={() => window.scrollTo(0, 0)}>
                      News
                    </Link>
                  </li>
                  <li>
                    <Link
                      exact
                      to="/"
                      className="footer_link"
                      onClick={() => window.scrollTo(0, 0)}>
                      Testimonial
                    </Link>
                  </li>
                  <li>
                    <Link
                      exact
                      to="/Blog"
                      className="footer_link"
                      onClick={() => window.scrollTo(0, 0)}>
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link
                      exact
                      to="/Contact"
                      className="footer_link"
                      onClick={() => window.scrollTo(0, 0)}>
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col">
                <h5 className="footer_title">Services</h5>
                <ul className="footer_list">
                  <li>
                    <Link
                      to="/DigitalEngineeringServiceList"
                      className="footer_link"
                      onClick={() => window.scrollTo(0, 0)}>
                      Digital Engineering
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/AIDataServicesList"
                      className="footer_link"
                      onClick={() => window.scrollTo(0, 0)}>
                      AI Data Services
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/AIMLComputerVisionList"
                      className="footer_link"
                      onClick={() => window.scrollTo(0, 0)}>
                      AI/ML & Computer Vision
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/ElectronicsEmbededList"
                      className="footer_link"
                      onClick={() => window.scrollTo(0, 0)}>
                      Electronics & Embedded Design
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/CloudServiceList"
                      className="footer_link"
                      onClick={() => window.scrollTo(0, 0)}>
                      Cloud Services
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/SapServiceList"
                      className="footer_link"
                      onClick={() => window.scrollTo(0, 0)}>
                      SAP Services
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col">
                <h5 className="footer_title">Industry</h5>
                <ul className="footer_list">
                  <li>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        handleMegaMenuClick("/Industry", "section1")
                      }
                      className="footer_link">
                      Aerospace And Defense
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        handleMegaMenuClick("/Industry", "section2")
                      }
                      className="footer_link">
                      Automotive
                    </a>
                  </li>

                  <li>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        handleMegaMenuClick("/Industry", "section4")
                      }
                      className="footer_link">
                      Semiconductor
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        handleMegaMenuClick("/Industry", "section5")
                      }
                      className="footer_link">
                      Medical Devices & Healthcare
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        handleMegaMenuClick("/Industry", "section6")
                      }
                      className="footer_link">
                      Oil & Gas
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        handleMegaMenuClick("/Industry", "section7")
                      }
                      className="footer_link">
                      Paper & Pulp
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        handleMegaMenuClick("/Industry", "section9")
                      }
                      className="footer_link">
                      Industrial and Heavy Equipment
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        handleMegaMenuClick("/Industry", "section3")
                      }
                      className="footer_link">
                      Rail Transportation
                    </a>
                  </li>
                </ul>
              </div>
              {/* <div className="col">
                <h5 className="footer_title">Insights</h5>
                <ul className="footer_list">
                  <li>
                    <a href="#" className="footer_link">
                      Seismic Anchor Design
                    </a>
                  </li>
                  <li>
                    <a href="#" className="footer_link">
                      Structural Analysis For Seismic Loads
                    </a>
                  </li>
                  <li>
                    <a href="#" className="footer_link">
                      Corporate Social Responsibility
                    </a>
                  </li>
                  <li>
                    <a href="#" className="footer_link">
                      Sustainability
                    </a>
                  </li>
                  <li>
                    <a href="#" className="footer_link">
                      Workplace
                    </a>
                  </li>
                  <li>
                    <a href="#" className="footer_link">
                      Society
                    </a>
                  </li>
                  <li>
                    <a href="#" className="footer_link">
                      Financials
                    </a>
                  </li>
                  <li>
                    <a href="#" className="footer_link">
                      News
                    </a>
                  </li>
                  <li>
                    <a href="#" className="footer_link">
                      Events
                    </a>
                  </li>
                </ul>
              </div> */}
              <div className="col">
                <h5 className="footer_title">Contact Us</h5>
                <ul className="footer_address_list">
                  <li className="ps-2">
                    <a href="#" className="contact_link">
                      +1 (866) 898-9971
                      <br />
                      +1 (408) 625-7226
                      <br />
                      +91 95858 88855
                    </a>
                  </li>
                  <li className="ps-2">
                    <a href="mailto:info@vistaes.com" className="mail_link">
                      info@vistaes.com
                      <br />
                      career@vistaes.com
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://forms.gle/bYmVvnbhYBSBt4iQ7"
                      className="footer_link">
                      Vendor Enrolment Form
                    </a>
                  </li>
                  <li>
                    <a href="#" className="footer_link">
                      AI Data Services Input Form
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://forms.gle/xfqJUXQRuFicDDh27"
                      className="footer_link">
                      Structural PE Input Form
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer_bottom">
              <div className="row gy-4">
                <div className="col-lg-5 col-md-12">
                  <p className="copyright_text text-center text-lg-start">
                    Copyright © 2024 VISTA Engineering Solutions Inc.
                  </p>
                </div>
                <div className="col-lg-3">
                  <p className="footer_text text-center mb-0">
                    Total Visitors Count {visitorCount.count}
                  </p>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div class="hstack gap-2 justify-content-center justify-content-md-end">
                    <div class="footer_text">Terms of use</div>
                    <div class="vr"></div>
                    <div class="footer_text"> Privacy</div>
                    <div class="vr"></div>
                    <div class="footer_text"> Policy </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
